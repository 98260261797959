var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Showing")]),_c('b-form-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"options":_vm.pageOptions,"id":"perPageSelect"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("entries")])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)])],1)],1),_c('b-table',{ref:"refUserListTable",staticClass:"position-relative pb-2 mb-0",attrs:{"responsive":"","primary-key":"id","items":_vm.listClients,"empty-text":"No reservations found","show-empty":"","fields":_vm.fields,"filter":_vm.searchQuery,"perPage":_vm.perPage,"id":"my-table","current-page":_vm.currentPage},scopedSlots:_vm._u([{key:"cell(name_selfpay)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.self_pay.name + ' ' + item.self_pay.lastname)+" ")])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status === 0 ? 'colorpago': 'colornopago'},[_vm._v(" "+_vm._s(item.status === 0 ? 'paid out' : 'no paid out')+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL,"transition":"scale-transition","offset-y":true},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true},{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('b-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","ripple":""}},'b-btn',attrs,false),on))]}}],null,true)},[_c('b-list-group',{staticStyle:{"padding":"2px","margin-bottom":"2px"},attrs:{"dense":"","rounded":""}},[_c('router-link',{staticClass:"urlPagina",attrs:{"to":{ name: 'details-driver-view' }}},[_c('b-list-group-item',{staticClass:"urlPagina",staticStyle:{"padding":"0"},attrs:{"ripple":false}},[_c('b-list-group-item',{staticClass:"font-weight-bold",staticStyle:{"border":"none","padding":"5px"}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_vm._v(" Delete ")],1)],1)],1)],1)],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.perPage)+" of "+_vm._s(_vm.listClients.length)+" entries")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"per-page":_vm.perPage,"total-rows":_vm.rows,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item","aria-controls":"my-table"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }